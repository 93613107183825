import React, { Component } from 'react'
import Img from 'gatsby-image'
import Lightbox from 'react-image-lightbox'
import { graphql } from 'gatsby'

import 'react-image-lightbox/style.css'
import './gallery.scss'

export default class Gallery extends Component {
  constructor(props) {
    super(props)

    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
      images: props.data.edges.map(
        ({
          node: {
            childImageSharp: {
              original: { src },
            },
          },
        }) => src
      ),
    }
  }
  gotoNextLightboxImage() {
    this.setState({
      currentImage: (this.state.currentImage + 1) % this.state.images.length,
    })
  }

  gotoPrevLightboxImage() {
    this.setState({
      currentImage:
        (this.state.currentImage + this.state.images.length - 1) %
        this.state.images.length,
    })
  }

  openLightbox(index) {
    this.setState({ lightboxIsOpen: true, currentImage: index })
  }

  closeLightbox() {
    this.setState({ lightboxIsOpen: false })
  }

  render() {
    return (
      <div className="gallery">
        {this.props.data.edges.map(({ node }, index) => (
          // eslint-disable-next-line
          <a href="#" onClick={this.openLightbox.bind(this, index)} key={index}>
            <Img
              style={{ width: 171, height: 171 }}
              fixed={node.childImageSharp.fixed}
            />
          </a>
        ))}
        {this.state.lightboxIsOpen && (
          <Lightbox
            mainSrc={this.state.images[this.state.currentImage]}
            nextSrc={
              this.state.images[
                (this.state.currentImage + 1) % this.state.images.length
              ]
            }
            prevSrc={
              this.state.images[
                (this.state.currentImage + this.state.images.length - 1) %
                  this.state.images.length
              ]
            }
            onCloseRequest={() => this.setState({ lightboxIsOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                currentImage:
                  (this.state.currentImage + this.state.images.length - 1) %
                  this.state.images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                currentImage:
                  (this.state.currentImage + 1) % this.state.images.length,
              })
            }
          />
        )}
      </div>
    )
  }
}

export const galleryItemsFragment = graphql`
  fragment GalleryItems on FileConnection {
    edges {
      node {
        childImageSharp {
          fixed(width: 171, height: 171) {
            ...GatsbyImageSharpFixed
          }
          original {
            width
            src
          }
        }
      }
    }
  }
`
